import React from 'react'
import { Grid } from '@material-ui/core'
import { LinkProps } from 'next/link'

export type UtilityBarWrapperProps = Omit<React.ComponentProps<typeof Grid>, 'direction' | 'justifyContent' | 'alignItems'> & Pick<LinkProps, 'href'> & {
  title: string
}

export const UtilityBarWrapper: React.FC<UtilityBarWrapperProps> = ({ title, href, children, ...props }) => {
  return (
    <Grid {...props}
      item
      direction="column"
      justifyContent="flex-start"
      alignItems="center">
      {children}
    </Grid>
  )
}
