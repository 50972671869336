import React, { useContext } from 'react'
import { Icon } from '../icon/icon'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { faFacebookSquare, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { breakpointContext } from './footer'
import Image from 'next/image'

const SocialIcons = styled(Icon)`
  color: rgba(255, 255, 255, 0.45);
  font-size: ${props => props.theme.typography.pxToRem(34)};
  background: none;

  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(34)};
  }
`

const AppStoreLogo = styled.a`
  width: ${props => props.theme.typography.pxToRem(178)};
  margin-left: ${props => props.theme.typography.pxToRem(5)} !important;

`

const PlayStoreLogo = styled.a`
width: ${props => props.theme.typography.pxToRem(178)};
margin-left: ${props => props.theme.typography.pxToRem(10)} !important;
`

const AppImage = styled(Image)`
  border: ${props => props.theme.typography.pxToRem(2)} solid rgba(255, 255, 255, 0.45) !important;
  border-radius: ${props => props.theme.typography.pxToRem(5)};

  &:hover {
    border: ${props => props.theme.typography.pxToRem(2)} solid rgba(255, 255, 255, 0.8) !important;
  }
`

const ConnectHeading = styled(Typography)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => props.theme.typography.pxToRem(22)};
  text-align: center;
  margin-top: ${props => props.theme.typography.pxToRem(46)};
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('lg')} {
    text-align: unset;
    margin-bottom: ${props => props.theme.typography.pxToRem(30)};
    margin-top: ${props => props.theme.typography.pxToRem(117)};
  }
`

const SocialRow = styled(Grid)`
  max-width: ${props => props.theme.typography.pxToRem(340)};
  margin-bottom: ${props => props.theme.typography.pxToRem(22)};
`
const SALogoContainer = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(25)};
`

export const FooterConnect: React.FC = () => { // TODO: Setup links
  const { desktop } = useContext(breakpointContext)

  return (
    <Grid
      item
      md={12}
      sm={12}
    >
      <Grid
        container
        direction="column"
        justifyContent={desktop ? 'flex-start':'center'}
        alignItems={desktop ? 'flex-start':'center'}
      >
        <ConnectHeading>Connect</ConnectHeading>
        <SocialRow
          container
          direction="row"
          justifyContent={desktop? 'space-between' : 'space-around'}
          alignItems="center"
        >
          <Grid
            item
          >
            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/CreditUnionSA"><SocialIcons icon={faFacebookSquare} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/CreditUnionSA"><SocialIcons icon={faTwitter} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/user/CreditUnionSA"><SocialIcons icon={faYoutube} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target="_blank" href="https://instagram.com/CreditUnionSA"><SocialIcons icon={faInstagram} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/Credit-Union-SA"><SocialIcons icon={faLinkedin} /></a>
          </Grid>
        </SocialRow>
        <Grid
          container
          direction="row"
          justifyContent={desktop ? 'flex-start' : 'center'}
          alignItems="center"
        >
          <PlayStoreLogo rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=au.com.cusa.android&hl=en"><AppImage src="/images/appIcons/playStore.svg" alt="" height={63} width={208} /></PlayStoreLogo>
          <AppStoreLogo rel="noopener noreferrer" target="_blank" href="https://itunes.apple.com/au/app/mobile-banking/id739541643?mt=8"><AppImage src="/images/appIcons/appStore.svg" alt=""  height={63} width={208} /></AppStoreLogo>
        </Grid>
        <SALogoContainer

        >
          <a href="http://www.southaustralia.com/" rel="noopener noreferrer" target="_blank">
            <Image src="/images/southAusLogo.svg" alt="" height={desktop?  53 : 43} width={desktop? 153 : 125}/>
          </a>
        </SALogoContainer>

      </Grid>
    </Grid>
    
  )
}