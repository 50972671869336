import React, { useCallback, useEffect } from 'react'
import Head from 'next/head'
import parseHtml from 'html-react-parser'
import { GlobalContext, GlobalContextType } from '../../globals/context'
import { isBrowser } from '@atomixdesign/rem-scaling'
import { useIsPreview, usePreviewScrollPosition } from '../../hooks/usePreview'
import { FooterFragment } from './fragments/footer'
import { HeaderFragment } from './fragments/header'
import { withSimpleBoundary } from '../../boundaries/simple'

export type LayoutProps = {
  noindex?: boolean
  globals: GlobalContextType
}

const Main = withSimpleBoundary('main')

export const Layout: React.FC<LayoutProps> = ({ children, noindex, globals }) => {
  const { seo, footer, header } = globals
  const isPreview = useIsPreview()
  const [position, setPosition] = usePreviewScrollPosition()
  const setCurrentPosition = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (isBrowser()) {
        setPosition(window.scrollY)
      }
    })
  }, [setPosition])

  useEffect(() => {
    if (isPreview) {
      window.scrollTo(0, position)
      window.addEventListener('scroll', setCurrentPosition)

      return () => {
        window.removeEventListener('scroll', setCurrentPosition)
      }
    }
  }, [isPreview, position, setCurrentPosition])

  return (
    <GlobalContext.Provider value={globals}>
      <Head>
        {parseHtml(seo.title)}
        {parseHtml(seo.jsonLd)}
        {parseHtml(seo.link)}
        {parseHtml(seo.meta)}

        {noindex && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      <HeaderFragment { ...header } />

      <Main id="main">
        {children}
      </Main>

      <FooterFragment { ...footer } />
    </GlobalContext.Provider>
  )
}