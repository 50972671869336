import { LayoutQueryFunction } from '../layout'
import { client } from '../../apollo/client'
import { gql } from '@apollo/client'
import { SiteInfoFragment } from './__generated__/header'

export type HeaderGlobals = {
  navigation: HeaderNavigation
  siteInfo: SiteInfoFragment
}

export const headerGlobalsDefault: HeaderGlobals = {
  navigation: { nodes: [] },
  siteInfo: {},
}

const GET_NAVIGATION_QUERY = gql`
  fragment SiteInfoFragment on siteSettings_GlobalSet {
    siteLoginLink
    siteNumberBsb
    siteNumberPhone
  }

  query GetNavigationQuery($handle: String!) {
    nodes(navHandle: $handle, level: 1) {
      title
      url
      data
      type
      children {
        title
        url
        data
        type
        children {
          title
          url
          data
          type
        }
      }
    }
    siteInfo: globalSet(handle: "siteSettings") {
      ...SiteInfoFragment
    }
  }
`

type NodeType = {
  title: string
  url: string
  type: string | null
  data: string
  children?: NodeType[]
}

type NodeTypeWithData = {
  title: string
  url: string
  type: string | null
  data: Record<string, unknown>
  children: NodeTypeWithData[] | null
}

export type GetNavigationQueryResults = {
  nodes: NodeType[] 
  siteInfo: SiteInfoFragment
}

export type HeaderNavigation = {
  nodes: NodeTypeWithData[]
}

const dataToRecord = ({ data, children, ...node }: NodeType): NodeTypeWithData => {
  return {
    ...node,
    data: JSON.parse(data),
    children: children?.map(dataToRecord) ?? null,
  }
}

export const getHeaderGlobals: LayoutQueryFunction<HeaderGlobals> = async () => {
  const { data } = await client.query<GetNavigationQueryResults>({
    query: GET_NAVIGATION_QUERY,
    variables: {
      handle: 'primaryNavigation',
    },
  })

  return {
    navigation: {
      nodes: data.nodes.map(dataToRecord),
    },
    siteInfo: data.siteInfo,
  }
}