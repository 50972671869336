import React from 'react'
import {
  StyledButton,
  StyledCallButton,
  StyledHeaderText,
  StyledListItem,
  StyledListItemGrid,
  StyledTextSpan,
} from './styledMobileMenuItem'
import { Grid } from '@material-ui/core'
import { CreditUnionIcon } from '../../icon/CreditUnionIcon'

//Header Item
export type MobileMenuHeaderProps = React.ComponentProps<typeof StyledListItem> & {
  onClose?: () => void
}

export const MobileMenuHeader: React.FC<MobileMenuHeaderProps> = ({ phoneNumber, bsbNumber, onClose, ...props }) => {
  return (
    <StyledListItem $header {...props}>
      <StyledListItemGrid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item>
              <StyledCallButton href={`tel:${phoneNumber}`} variant="outlined" size="small">
                <StyledHeaderText>Call</StyledHeaderText>
                <StyledHeaderText $secondary>{phoneNumber}</StyledHeaderText>
              </StyledCallButton>
            </Grid>
            <Grid item>
              <StyledTextSpan>
                <StyledHeaderText>BSB</StyledHeaderText>
                <StyledHeaderText $secondary>{bsbNumber}</StyledHeaderText>
              </StyledTextSpan>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <StyledButton onClick={onClose}>
            <CreditUnionIcon iconCode={'times'} width={22} height={22} />
          </StyledButton>
        </Grid>
      </StyledListItemGrid>
    </StyledListItem>
  )
}
