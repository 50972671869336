import { LayoutQueryFunction } from '../layout'
import { getClient } from '../../apollo/client'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../fragments/image'
import { FallbackQuery } from './__generated__/fallback'

export type FallbackGlobals = FallbackQuery

export const fallbackDefault: FallbackGlobals = {}

export const FALLBACK_QUERY = gql`
  ${IMAGE_FRAGMENT}

  query FallbackQuery {
    globalSet {
      ... on siteSettings_GlobalSet {
        flexibleContentImageFallback {
          ...ImageFragment
        }
        newsFallbackImage {
          ...ImageFragment
        }
        productDetailImageFallback {
          ...ImageFragment          
        }
        productListingImageFallback {
          ...ImageFragment          
        }
      }
    }
  }

`

export const getFallbackGlobals: LayoutQueryFunction<FallbackGlobals> = async () => {
  const { data } = await getClient().query({
    query: FALLBACK_QUERY,
  })

  return data
}