import React, { useContext } from 'react'
import { breakpointContext } from './footer'
import { UnstyledLink } from './footerBottom'
import { Grid } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '../icon/icon'
import styled, { css } from 'styled-components'

type AccordionWrapperProps = {
  //
  }
type AccordionTitleProps = {
  //
  href?: string
  }
type AccordionListProps = {
  //
  }
type AccordionItemProps = {
  //
  href?: string
  }

type FooterAccordionProps = 
    | (
      {
        title?: never
        list?: never
        item?: never
      } & AccordionWrapperProps
    )
    | (
      {
        title: true
        list?: never
        item?: never
      } & AccordionTitleProps
    )
    | (
      {
        title?: never
        list: true
        item?: never
      } & AccordionListProps
    )
    | (
      {
        title?: never
        list?: never
        item: true
      } & AccordionItemProps
    )

const MobileAccordionTitle = styled.a`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  color: ${props => props.theme.palette.common.white};
  text-decoration: none;
`

const MobileTitleChevron = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  color: ${props => props.theme.palette.primary.main};
  background: none;
`

const FooterLink = styled.a`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  color: ${props => props.theme.palette.background.default};
  text-align: left;
  opacity: 0.75;
  text-decoration: none;
  line-height: ${props => props.theme.typography.pxToRem(25)};

  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }
`
const LinkList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-top: unset;
  margin-bottom: unset;

  & > li {
    display: list-item;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-top: ${props => props.theme.typography.pxToRem(10)};
  }
`

const FooterLinkWrap = styled(UnstyledLink)`
  max-width: ${props => props.theme.typography.pxToRem(205)};
  padding-top: ${props => props.theme.typography.pxToRem(10)};
  margin-bottom: 0;
`
const DarkAccordion = styled(Accordion)`
  background-color: ${props => props.theme.palette.primary.dark};
  width: 90%;
  border-top: solid ${props => props.theme.typography.pxToRem(1)} rgba(255, 255, 255, 0.45);
  border-radius: unset !important;
  box-shadow: none;
`

export const DesktopAccordionTitle = styled.a<{ $unlinked?: boolean }>`
  font-size: ${props => props.theme.typography.pxToRem(22)};
  color: ${props => props.theme.palette.common.white};
  text-decoration: none;
  margin-bottom: ${props => props.theme.typography.pxToRem(15)};
  margin-top: ${props => props.theme.typography.pxToRem(96)};

  &:hover {
    opacity: 70%;
    color: ${props => props.theme.palette.common.white};
  }

  ${props => props.$unlinked && css`
    pointer-events: none;
    cursor: default;
  `}
`

export const DesktopTitleChevron = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  color: ${props => props.theme.palette.primary.main};
  background: none;
  margin-left: ${props => props.theme.typography.pxToRem(15)};
`


export const FooterAccordion: React.FC<FooterAccordionProps> = ({ title, list, item, children, ...props }) => {
  if (title) {
    return (
      <AccordionTitle {...props}>
        {children}
      </AccordionTitle>
    )
  }

  if (list) {
    return  (
      <FooterList {...props}>
        {children}
      </FooterList>
    )
  }

  if (item) {
    return (
      <FooterListItem {...props}>
        {children}
      </FooterListItem>
    )
  }

  return (
    <FooterAccordionWrapper>
      {children}
    </FooterAccordionWrapper>
  )
}

const FooterAccordionWrapper: React.FC<AccordionWrapperProps>  = ({ children }) => {
  const { desktop } = useContext(breakpointContext)

  return (
    <React.Fragment>
      {desktop ? (
        <Grid
          item
          xs={5}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            {children}
          </Grid>
        </Grid>
      ) : (
        <>
          {children && (
            <DarkAccordion>
              {children}
            </DarkAccordion>
          )}
        </>
      )}
    </React.Fragment>
  )
}

const AccordionTitle: React.FC<AccordionTitleProps>  = ({ href, children, ...props }) => {
  const { desktop } = useContext(breakpointContext)

  return (
    <React.Fragment>
      {desktop ? (
        <DesktopAccordionTitle {...props} href={href} $unlinked={!href}>
          {children}
          {href && <DesktopTitleChevron icon={faChevronRight} /> }
        </DesktopAccordionTitle>
      ) : (
        <AccordionSummary
          {...props}
          expandIcon={<MobileTitleChevron icon={faChevronDown} />}
        >
          <MobileAccordionTitle>
            {children}
          </MobileAccordionTitle>
        </AccordionSummary>
      )}
    </React.Fragment>
  )
}

const FooterList: React.FC<AccordionListProps>  = ({ children, ...props }) => {
  const { desktop } = useContext(breakpointContext)

  return (
    <React.Fragment>
      {desktop ? (
        <LinkList {...props}>{children}</LinkList>
      ) : (
        <AccordionDetails {...props}>
          <LinkList>
            {children}
          </LinkList>
        </AccordionDetails>
      )}
    </React.Fragment>
  )
}

const FooterListItem: React.FC<AccordionItemProps> = ({ href, children, ...props }) => {
  return (
    <FooterLinkWrap>
      <FooterLink {...props} href={href}>{children}</FooterLink>
    </FooterLinkWrap>

  )
}